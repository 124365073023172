import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import style from "./logo.module.css"


const NavItem = styled(Link)`
  text-decoration: none;
  color: #111;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background:gray;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: gray;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 15px 0;
    font-size: 1.5rem;
    z-index: 6;
  }
`
const NavbarLinks = () => {
  return (
    <>
      <NavItem to="/">Home</NavItem>
      <NavItem to="/AboutUs">About Us</NavItem>
      <div className={style.subnav}>
      <NavItem className={style.subnavbtn}>Services
      
  <div className={style.sub}>
      <Link className={style.link} to="/BusinessTesting">Business Testing</Link>
      <Link className={style.link} to="/MobileAppTesting">Mobile App Testing</Link>
      <Link className={style.link} to="/AgileTesting">Agile Testing</Link>
      <Link className={style.link} to="/CrossBrowserTesting">Cross Browser Testing</Link>
      <Link className={style.link} to="/SecurityTesting">Security Testing</Link>
    </div></NavItem></div>
      <NavItem className={style.padding} to="/Tools">Tools</NavItem>
      <NavItem to="/Career">Careers</NavItem>
      <NavItem to="/Contact">Contact Us</NavItem>
      {/* <NavItem to="/">DictateExec</NavItem> */}
    </>
  )
}

export default NavbarLinks
import React from "react"
// import Img from "gatsby-image"
// import { useStaticQuery, graphql } from "gatsby"
// import {Link} from "gatsby"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faLinkedinIn, faTwitter} from '@fortawesome/free-brands-svg-icons'

const Icon=styled.div`
 text-align:center;
 padding:20vmin;
 background-color:lightgray;
 width:100%;
`
const U=styled.a`
font-size:1.75em;
padding: 0em 0.5em;
color: #000;
box-shadow:none;
`
  

const Footer = () => {
  return (
    <Icon>
    <U href='https://twitter.com' >
      <FontAwesomeIcon icon={faTwitter}/>
    </U>
    <U href='https://github.com/${social.github'>
      <FontAwesomeIcon icon={faFacebook}/>
    </U>
    <U href='https://www.linkedin.com/company/prueba-india'>
      <FontAwesomeIcon icon={faLinkedinIn}/>
    </U>
  </Icon>
  )
}

export default Footer
import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import {Link} from "gatsby"
import style from "./logo.module.css"

const Logo = () => {
    const data = useStaticQuery(graphql`
      query {
        file(name: { eq: "logo" }, extension: { eq: "png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, pngQuality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)
  return (
      <div className={style.logo} as={Link} to="/">
      <Img fluid={data.file.childImageSharp.fluid} alt="logo" />
      </div>
  )
}

export default Logo